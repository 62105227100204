import { createSlice } from '@reduxjs/toolkit'
export const accountsSlice = createSlice({
    name: 'accounts',
    initialState: {
        isLoading: false,
        operationType: "",
        accountList: [],
        accountDetails: undefined, 
        dateRangedAccountsList: []
    },
    reducers: {
        getAllAccounts: (state, action) => {
            state.isLoading = true;
        },
        sagaFailed: (state, action) => {
            state.isLoading = false;
            state.operationType = ""
        },
        sagaSuccess: (state, action) => {
            if (action.payload) {
                Object.assign(state, action.payload);
            }
        },
        getAccountDetails: (state, action) => {
            state.isLoading = true;
        },
        createAccount: (state, action) => {
            state.isLoading = true;
        },
        resetOperationType: (state, action) => {
            state.operationType = ""
        },
        linkAccount: (state, action) => {
            state.isLoading = true;
        },
        deleteAccount: (state, action) => {
            state.isLoading = true;
        },
        getAccountsListByDateRange: (state, action) => {
            state.isLoading = true; 
        }, 
        delinkAccount: (state, action) => {
            state.isLoading = true 
        },
        deleteLinkedAccount: (state, action) => {
            state.isLoading = true 
        }
    }
})

export const {
    getAllAccounts,
    getAccountsListByDateRange,
    sagaFailed,
    sagaSuccess,
    getAccountDetails,
    createAccount,
    resetOperationType,
    linkAccount,
    deleteAccount, 
    delinkAccount, 
    deleteLinkedAccount 
} = accountsSlice.actions

export default accountsSlice.reducer