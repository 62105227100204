'use client';
import React from "react";
import PrefixIconButton from "../buttons/prefixIconButton";
import LinkWrapper from "../linkWrapper/page";
import colors from "../../libs/colorFactory";
import "./style.css";

const EmptyPage: React.FC<any> = ({ mainHeading, headingDescription, redirectTo, btnName, imgLink }) => {
  return (
    <div className='ep-empty'>
      <div className="ep-card">
        <div className="ep-contentDiv">
          <img src={imgLink || "/images/no data.png"} style={{ height: 54, width: 54 }} />
          <div className="ep-mainHeading">{mainHeading}</div>
          {headingDescription && <div className="ep-headingDescription">{headingDescription}</div>}
          {redirectTo &&
            <LinkWrapper href={redirectTo}>
              <div className="ep-displayFlexCenter">
                <PrefixIconButton
                  title={btnName}
                  backgroundColor={"#7413BD"}
                  fontColor={colors.color_white}
                  paddingLeft="30px"
                  paddingRight="30px"
                  marginTop="8px"
                  height={"40px"}
                />
              </div>
            </LinkWrapper>}
        </div>
      </div>
    </div >
  )
}
export default EmptyPage;