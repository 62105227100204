'use client';
import React, { useEffect, useRef, useState } from "react";
import { Form, Input, Row, Col, Upload, Select, Radio, DatePicker, Checkbox, TimePicker, Tooltip, Switch } from "antd";
import { UploadOutlined } from '@ant-design/icons';
import formatMsg from "../../util/formatMsg";
import PhoneInput from "react-phone-input-2";
import colors from "../../libs/colorFactory";
import "./style.css";
import 'react-phone-input-2/lib/style.css'
import Helper from "../../util/helper";

import {
    GetCountries,
    GetState
} from "react-country-state-city";


const FormWrapper: React.FC<any> = (props) => {

    let { formFields, values, setValues, countrySelectorVisible, stateSelectorVisible, form, setPreviewObj } = props;
    // Whenever you want to use "country selector" and "state selector" in form 
    // mention them in formFields array as type [name: 'stateName', name: 'countryName'], and pass countrySelector={true} and stateSelector={true} and form={form} in FormWrapper props 
    // You can use Country and State Selector both, Just country selector also, but you cannot use just state selector in FormWrapper as of now 

    // Whenever you use country and state selector both in a FormWrapper, make sure to render the wrapping modal like this if rendering as a pop-up modal 
    /*
        {
            modal?.open && <AddEditCenterModal
                modal={modal}
                setModal={setModal}
                firebase={firebase}
                multicenterReducer={multicenterReducer}
            />
        }
    */

    const [countryName, setCountryName] = useState();
    const [stateName, setStateName] = useState();

    const [countryList, setCountryList] = useState([]);
    const [stateList, setStateList] = useState([]);

    const countryNameToIndexMap = useRef(new Map());
    const stateNameToIndexMap = useRef(new Map());

    const [selectedRadioVal, setSelectedRadioValue] = useState(null);

    const handleRadioChange = (fieldName: string, newSelectionVal: string) => {

        if (selectedRadioVal === newSelectionVal) {
            setSelectedRadioValue(null);
            form.setFieldsValue({ [fieldName]: null });
            if (setPreviewObj) {
                setPreviewObj({
                    ...form.getFieldsValue(),
                    [fieldName]: null
                });
            }
        }
        else {
            setSelectedRadioValue(newSelectionVal);
            form.setFieldsValue({ [fieldName]: newSelectionVal });
        }
    };

    useEffect(() => {
        if (countrySelectorVisible) {
            GetCountries().then((countriesList) => {

                if (countriesList) {
                    countriesList.forEach((countryObj) => {
                        countryNameToIndexMap.current.set(countryObj.name, countryObj.id);
                    });
                }

                setCountryList(countriesList ? countriesList : []);
                const currentCountryNameInEditableRecord = form.getFieldValue('country');

                if (currentCountryNameInEditableRecord) {

                    const countryIndex = countryNameToIndexMap?.current?.get(currentCountryNameInEditableRecord);

                    GetState(countryIndex).then((statesList) => {
                        if (statesList) {
                            statesList.forEach((stateObj) => {
                                stateNameToIndexMap?.current?.set(stateObj.name, stateObj.id);
                            });
                        }
                        setStateList(statesList ? statesList : []);
                    });
                }
            });
        }

        return () => {
            setStateList([]);
        }
    }, []);

    const onCountryChange = (index) => {

        const country = countryList[index];
        setCountryName(country?.name);
        form.setFieldValue('country', country?.name);

        if (stateSelectorVisible) {

            setStateName(undefined);
            form.resetFields(['state']);
            setStateList([]);

            if (country) {
                GetState(country.id).then((statesList) => {
                    if (statesList) {
                        statesList.forEach((stateObj) => {
                            stateNameToIndexMap.current.set(stateObj.name, stateObj.id);
                        });
                    }
                    setStateList(statesList ? statesList : []);
                });
            }
        }
    }

    const onStateChange = (index) => {
        const state = stateList[index];
        setStateName(state?.name);
        form.setFieldValue('state', state?.name);
    }

    const { Dragger } = Upload;
    const { TextArea } = Input;
    const { RangePicker } = DatePicker;

    const handleValueChange = (fieldName, value) => {
        if (values && setValues) {
            let obj: any = { ...values };
            obj[fieldName] = value;
            setValues(obj);
        }
    }

    const getMediaProfiles = (field) => {
        if (props?.editableRecords) {
            if (!field?.multiple) {
                return props?.editableRecords[field.name] && <div className="form-imgContainer">
                    <img src={props?.editableRecords[field.name]} className="form-imgStyle"></img>
                    <img src="/images/delete.png" className="iconStyle" onClick={() => handleImageChange(field)}></img>
                </div>
            }
            return props?.editableRecords && props?.editableRecords[field.name]?.map((item, index) => {
                return (
                    <div className="form-imgContainer">
                        <img src={item.path} className="form-imgStyle"></img>
                        <img src="/images/delete.png" className="iconStyle" onClick={() => handleImageChange(field, index)}></img>
                    </div>
                )
            })
        }
    }
    const handleImageChange = (field, index?) => {
        let newObj = { ...props?.editableRecords };
        if (!field.multiple) {
            newObj[field.name] = undefined;
        } else {
            newObj[field.name] = newObj[field.name].filter((item, i) => i !== index);
        }
        props.setEditableRecords(newObj);
    }

    const handleSelectAll = (value, options) => {
        if (value && value.length && value.includes("all")) {
            if (value.length === options.length + 1) {
                return [];
            }
            let optionsId = options?.map((item) => item.id).filter((item) => item !== "all");
            return [...optionsId];
        }
        return value;
    }


    const renderFormFields: any = () => {
        return formFields?.map((fieldCollection: any) => {
            let span = 24 / fieldCollection.length;
            let fields = [];
            fieldCollection?.map((field) => {
                if (field.header) {
                    fields.push(
                        <Col span={24} className="subheader">{field.header}</Col>
                    )
                }
                if (span === 24) {
                    fields.push(
                        <Col lg={span} md={24} sm={24} xs={24}>
                            {getFields(field)}
                        </Col>
                    )
                } else {
                    fields.push(
                        <Col lg={span - 1} md={24} sm={24} xs={24}>
                            {getFields(field)}
                        </Col>
                    )
                }
            });
            return <Row typeof="flex" justify="space-between" className="form-wrapper-main-row">{fields}</Row>
        });
    }

    const getMediaField = (field) => {

        const fieldLabel = field.toolTip ?
            <Tooltip
                title={field.toolTip.title}
                placement={field.toolTip.placement ? field.toolTip.placement : 'top'}
            >
                {field.label}
            </Tooltip>
            :
            field.label;

        if (field.multiple) {
            return <>
                <Form.Item<any>
                    label={fieldLabel}
                    name={field.name}
                    rules={[{ required: field.required, message: field.warningMessage }]}
                >

                    <Dragger
                        name="file"
                        multiple={field.multiple}
                        listType={field.inputType}
                        maxCount={!field.multiple ? 1 : null}
                        className="upload-list-inline"
                    >

                        <div className='upload-style'>
                            <div className="ant-upload-drag-icon">
                                <UploadOutlined />
                            </div>
                            <div className="upload-text">Click or drag to attach photo</div>
                        </div>
                    </Dragger>
                </Form.Item>
                {getMediaProfiles(field)}
            </>
        } else {
            if (props?.editableRecords && props?.editableRecords[field.name]) {
                return getMediaProfiles(field);
            } else {
                return <Form.Item<any>
                    label={fieldLabel}
                    name={field.name}
                    rules={[{ required: field.required, message: field.warningMessage }]}
                >
                    <Dragger
                        name="file"
                        multiple={field.multiple}
                        listType={field.inputType}
                        maxCount={!field.multiple ? 1 : null}
                        className="upload-list-inline"
                    >
                        <div className='upload-style'>
                            <div className="ant-upload-drag-icon">
                                <UploadOutlined />
                            </div>
                            <div className="upload-text">Click or drag to attach photo</div>
                        </div>
                    </Dragger>
                </Form.Item>
            }

        }
    }

    const getFields = (field) => {

        const fieldLabel = field.toolTip ?
            <Tooltip
                title={field.toolTip.title}
                placement={field.toolTip.placement ? field.toolTip.placement : 'top'}
            >
                {field.label}
            </Tooltip>
            :
            field.label;

        switch (field.type.toLowerCase()) {
            case "input":
                return <Form.Item<any>
                    label={fieldLabel}
                    name={field.name}
                    // rules={[{ required: field.required, message: field.warningMessage }]}
                    rules={[
                        { required: field.required, message: field.warningMessage, whitespace: field.inputType === "string" && field.required },
                        field.inputType === "email" ? { type: 'email', message: formatMsg("error.validEmail") } : {}
                    ]}
                    initialValue={field.initialValue || undefined}
                >
                    <Input
                        maxLength={field.maxLength}
                        type={field.inputType}
                        placeholder={field.placeHolder}
                        disabled={field.disabled}
                    />
                </Form.Item>

            case "phoneinput":
                return <Form.Item<any>
                    label={
                        (fieldLabel) &&
                        (
                            field.required ?
                                <div>
                                    <span className='color-red margin-right-3'>*</span>
                                    {fieldLabel}
                                </div>
                                :
                                fieldLabel
                        )
                    }
                    rules={[{ required: field.required, message: field.warningMessage }]}
                >
                    <div className="isoInputWrapper">
                        <PhoneInput
                            onChange={field.onPhoneChange}
                            preferredCountries={["in", "au", "sa"]}
                            placeholder={formatMsg("page.phoneNumber")}
                            inputStyle={{
                                width: "100%",
                                borderColor: colors.color_lightGrey,
                                color: "black"
                            }}
                            disabled={field.disabled}
                            country={Helper.getCountryCode()}
                            localization={Helper.getDefaultLanguageKey()}
                            value={field.phoneValue ? field.phoneValue : ""}
                        />
                    </div>
                </Form.Item>

            case "media":
                return getMediaField(field);


            case "textarea":
                return <Form.Item<any>
                    label={fieldLabel}
                    name={field.name}
                    rules={[{ required: field.required, message: field.warningMessage }]}
                >
                    <TextArea
                        placeholder={field.placeHolder}
                        rows={field.rows || 5}
                        disabled={field.disabled}
                    />
                </Form.Item>

            case "select":
                if (field?.extraContent) {
                    return <> <Form.Item<any>
                        label={fieldLabel}
                        name={field.name}
                        rules={[{ required: field.required, message: field.warningMessage }]}
                        initialValue={field.initialValue || undefined}
                        getValueFromEvent={(values) => handleSelectAll(values, field.options)}
                    >
                        <Select
                            placeholder={field.placeHolder}
                            mode={field.mode}
                            disabled={field.disabled}
                            showSearch
                            onChange={(val) => handleValueChange(field.name, val)}
                            allowClear={field.mode == "multiple" || !field.required ? true : false}
                        >
                            {
                                field?.options?.map((item) => {
                                    let val = field.optionKey ? item[field.optionKey] : item;
                                    let label = field.optionLabel ? item[field.optionLabel] : item;
                                    return (
                                        <Select.Option value={val}>{label}</Select.Option>
                                    )
                                }
                                )
                            }
                        </Select>
                    </Form.Item>
                        {field?.extraContent}
                    </>
                }
                else {
                    return <Form.Item<any>
                        label={fieldLabel}
                        name={field.name}
                        rules={[{ required: field.required, message: field.warningMessage }]}
                        initialValue={field.initialValue || undefined}
                        getValueFromEvent={(values) => handleSelectAll(values, field.options)}
                        className={field.formItemCss ? field.formItemCss : ''}
                    >
                        <Select
                            placeholder={field.placeHolder}
                            mode={field.mode}
                            disabled={field.disabled}
                            showSearch
                            onChange={(val) => handleValueChange(field.name, val)}
                            allowClear={field.mode == "multiple" || !field.required ? true : false}
                        >
                            {
                                field?.options?.map((item) => {
                                    let val = field.optionKey ? item[field.optionKey] : item;
                                    let label = field.optionLabel ? item[field.optionLabel] : item;
                                    return (
                                        <Select.Option value={val} disabled={field.optionDisabled ? field.optionDisabled(item) : false}>{field.skipFormat || !label ? label : formatMsg(label)}</Select.Option>
                                    )
                                }
                                )
                            }


                        </Select>
                    </Form.Item>
                }

            case "radio":
                return <Form.Item<any>
                    label={fieldLabel}
                    name={field.name}
                    rules={[{ required: field.required, message: field.warningMessage }]}
                >
                    <Radio.Group
                        disabled={field.disabled}
                        value={selectedRadioVal}
                    >
                        {
                            field?.options?.map((item) => (
                                <Radio
                                    value={item.key}
                                    onClick={() => handleRadioChange(field.name, item.key)}
                                >
                                    <div style={{ textTransform: "capitalize", marginTop: "5px" }}>
                                        {item.formattedLabel || formatMsg(item.label)}
                                    </div>
                                </Radio>
                            ))
                        }
                    </Radio.Group>
                </Form.Item>

            case "date":
                return <Form.Item<any>
                    label={fieldLabel}
                    name={field.name}
                    rules={[{ required: field.required, message: field.warningMessage }]}
                    initialValue={field.initialValue || undefined}
                >
                    <DatePicker
                        disabled={field.disabled}
                        style={{ width: '100%' }}
                        disabledDate={field.disabledDate}
                        allowClear={field.allowClear ?? true}
                        placeholder={field.placeHolder ? field.placeHolder : formatMsg('selectDate')}
                    />
                </Form.Item>

            case "daterange":
                return <Form.Item<any>
                    label={fieldLabel}
                    name={field.name}
                    rules={[{ required: field.required, message: field.warningMessage }]}
                >
                    <RangePicker
                        disabled={field.disabled}
                        style={{ width: '100%' }}
                        onChange={(val) => field.onChange ? field.onChange(val) : handleValueChange(field.name, val)}
                        disabledDate={field.disabledDate}
                    />
                </Form.Item>

            case "time":
                return <Form.Item<any>
                    label={fieldLabel}
                    name={field.name}
                    rules={[{ required: field.required, message: field.warningMessage }]}
                >
                    <TimePicker disabled={field.disabled}
                        // format="HH:mm"
                        style={{ width: '100%' }}
                        needConfirm={false} />

                </Form.Item>

            case "timerange":
                return <Form.Item<any>
                    label={fieldLabel}
                    name={field.name}
                    rules={[{ required: field.required, message: field.warningMessage }]}
                >
                    <TimePicker.RangePicker
                        needConfirm={false}
                        disabled={field.disabled}
                        style={{ width: '100%' }}
                        onChange={(val) => handleValueChange(field.name, val)}
                    />
                </Form.Item>

            case "checkbox":
                return <Form.Item<any>
                    label={fieldLabel}
                    name={field.name}
                    rules={[{ required: field.required, message: field.warningMessage }]}
                    valuePropName="checked"
                    initialValue={field.initialValue || undefined}
                >
                    <Checkbox disabled={field.disabled}>{field.options}</Checkbox>
                </Form.Item>

            case "legend":
                return <Row style={{ marginBottom: 14, color: colors.v3_Cerulean }}>
                    {field.value}
                </Row>

            case "normallabel":
                return <Row style={{ marginBottom: 7, fontSize: 'var(--font-size-15)', cursor: 'default' }}>
                    {fieldLabel}
                </Row>

            case "horizontal custom styled input":
                return <>
                    <Col style={styles.horizontalCustomInput.container} >
                        <Col style={styles.horizontalCustomInput.label} >
                            {fieldLabel}
                        </Col>
                        <Col style={styles.flexCenter} >
                            {
                                field.secondaryText &&
                                <p style={styles.horizontalCustomInput.secondaryText} >
                                    {field.secondaryText}
                                </p>
                            }
                            <Form.Item<any>
                                name={field.name}
                                rules={[
                                    { required: field.required, message: field.warningMessage, whitespace: field.inputType === "string" && field.required },
                                    field.inputType === "email" ? { type: 'email', message: formatMsg("error.validEmail") } : {}
                                ]}
                                initialValue={field.initialValue || undefined}
                                style={styles.horizontalCustomInput.formItem}
                            >
                                <Input
                                    style={field.inputStyle ? field.inputStyle : undefined}
                                    maxLength={field.maxLength}
                                    type={field.inputType}
                                    placeholder={field.placeHolder}
                                    disabled={field.disabled}
                                />
                            </Form.Item>
                        </Col>
                    </Col>
                    <div style={styles.greyDivider}></div>
                </>

            case "country selector":
                return (countrySelectorVisible) ? (
                    <Form.Item<any>
                        name={field.name}
                        rules={[{ required: field.required, message: field.warningMessage }]}
                        initialValue={field.initialValue || undefined}
                    >
                        <Select
                            value={countryName}
                            onChange={onCountryChange}
                            disabled={countryList.length === 0}
                            allowClear
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) => (
                                option.props.children
                                    .toString()
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase().trim()) >= 0
                            )}
                            placeholder={field.placeHolder}
                        >
                            {countryList.map((item, index) => (
                                <Select.Option key={index} value={item.index}>
                                    {item.name}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>)
                    :
                    (<></>)

            case "state selector":
                return (stateSelectorVisible) ? (
                    <Form.Item<any>
                        name={field.name}
                        rules={[{ required: field.required, message: field.warningMessage }]}
                        initialValue={field.initialValue || undefined}
                    >
                        <Select
                            value={stateName}
                            onChange={onStateChange}
                            disabled={stateList.length === 0}
                            allowClear
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) => (
                                option.props.children
                                    .toString()
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase().trim()) >= 0
                            )}
                            placeholder={field.placeHolder}
                        >
                            {stateList.map((item, index) => (
                                <Select.Option key={index} value={item.index}>
                                    {item.name}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>)
                    :
                    (<></>)

            case "switch":
                return (
                    <div style={styles.switchContainer}>
                        <Form.Item<any>
                            name={field.name}
                            rules={[{ required: field.required, message: field.warningMessage }]}
                            valuePropName="checked"
                            initialValue={field.initialValue || false}
                        >
                            <Switch
                                size={field.size}
                            />
                        </Form.Item>
                        <p style={styles.switchLabel}>
                            {fieldLabel}
                        </p>
                    </div>
                )
        }
    }

    return (
        <>
            {renderFormFields()}
        </>

    )
}


export default FormWrapper;


const styles = {
    horizontalCustomInput: {
        container: {
            display: 'flex',
            justifyContent: 'space-between',
            height: '40px',
            alignItems: 'center'
        },
        label: {
            padding: 0,
            margin: '0px 10px',
            color: colors.blackish_back,
            fontSize: 'var(--font-size-16)'
        },
        secondaryText: {
            padding: 0,
            margin: '0px 10px',
            color: colors.color_mediumGrey,
            fontSize: 'var(--font-size-15)',
            fontWeight: 300
        },
        formItem: {
            marginTop: '26px',
            height: '40px'
        }
    },
    greyDivider: {
        height: '0.5px',
        backgroundColor: colors.color_lightGrey,
        margin: '20px 0px'
    },
    flexCenter: {
        display: 'flex',
        alignItems: 'center',
        height: '40px'
    },
    switchLabel: {
        padding: 0,
        margin: '0px 0px 0px 10px',
        height: '40px',
        display: 'flex',
        alignItems: 'center'
    },
    switchContainer: {
        display: 'flex',
        marginTop: '-13px',
        marginLeft: '9px'
    }
}