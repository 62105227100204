'use client'


import React from 'react'
import { useSortable } from '@dnd-kit/sortable';

import type { FC, ReactNode } from 'react'


interface DraggableProps {
    id: string; 
    children: ReactNode; 
    normalStyle?: Record<string, string>; 
}; 


const Draggable: FC<DraggableProps> = (props) => {

    const { id, children, normalStyle } = props; 

    const { attributes, listeners, setNodeRef: setDraggableNodeRef, transform, transition } = useSortable({
        id 
    }); 

    const style = {
        transform: transform 
            ? `translate3d(${transform.x}px, ${transform.y}px, 0)` 
            : undefined,
        transition,
        backgroundColor: 'white', 
        ...(normalStyle) ? normalStyle : {},
    };

    return (
        <div 
            ref={setDraggableNodeRef} 
            style={style} 
            {...listeners} 
            {...attributes}
        > 
            {children} 
        </div> 
    )
} 


export default Draggable; 