import React from 'react'
import { ModalWrapper } from '../wrapperComponents/page';
import { Form, Spin } from 'antd';
import formatMsg from '../../util/formatMsg';
import FormWrapper from '../formWrapper/page';


const LinkUserToOtherLinkedAccounts = (props) => {

    const { linkedAccountsList, user, form, isLoading, isLinkUserModalVisible, handleLinkUserOk, handleLinkUserCancel } = props;

    const LinkUserToOtherLinkedAccountsFields = [
        [{
            heading: formatMsg('linkToAccounts'),
            label: formatMsg('doYouWantToLinkThis') + ' ' + (`"${user.name || ''}" user`) + ' ' + formatMsg('toYourLinkedAccounts') + '?',
            type: 'select',
            name: 'linkUserToAcccountIds',
            placeHolder: formatMsg('placeholder.account'),
            required: true,
            formItemCss: 'custom-form-item-label-margin',
            warningMessage: formatMsg('pleaseSelectAnAccount'),
            optionKey: 'id',
            optionLabel: 'name',
            options: linkedAccountsList ? linkedAccountsList : [],
            mode: "multiple"
        }]
    ];

    return (
        <ModalWrapper
            open={isLinkUserModalVisible}
            title={formatMsg("userCreatedSuccessfully")}
            onOk={handleLinkUserOk}
            onCancel={handleLinkUserCancel}
            cancelText={formatMsg("cancel")}
            okText={formatMsg("link")}
            maskClosable={false}
            confirmLoading={isLoading}
        >
            <Form
                form={form}
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                initialValues={{ remember: true }}
            >
                <FormWrapper
                    formFields={[...LinkUserToOtherLinkedAccountsFields]}
                />
            </Form>
        </ModalWrapper>
    )
}

export default LinkUserToOtherLinkedAccounts; 