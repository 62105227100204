"use client";
import "./style.css";
import Helper from "../../util/helper";
import { Row, Col, Popover } from "antd";
import { useEffect, useState } from "react";
import type { ReactElement } from "react";
import formatMsg from "../../util/formatMsg";
import colors from "../../libs/colorFactory";
import { UpOutlined, DownOutlined } from '@ant-design/icons';
import LinkWrapper from "../linkWrapper/page";
import { useDispatch } from "react-redux";
import { deleteAccountUserLink, delinkAccountFromUser, linkAccountToUser } from "../../redux/users/reducer";


const UserCard: React.FC<any> = (props) => {

    const { user, currentDetailedViewOfAccountId, firebase, index, accounts, action, deleteAction } = props;
    const [fields, setFields] = useState([]);
    const [showLinkedAccounts, setShowLinkedAccounts] = useState(false);
    const [clickedAccountId, setClickedAccountId] = useState('');
    const [clickedUserId, setClickedUserId] = useState('false');
    const [childConfigFieldIdToObjMap, setChildConfigFieldIdToObjMap] = useState(new Map());

    const dispatch = useDispatch();

    useEffect(() => {
        let fieldsToRender = ["name", "status"];
        const childConfigFieldIdToObjMap = new Map();
        if (user?.childConfig) {
            user.childConfig.forEach((customFieldObj) => {
                fieldsToRender.push(customFieldObj.id);
                childConfigFieldIdToObjMap.set(customFieldObj.id, customFieldObj);
            });
        }
        setChildConfigFieldIdToObjMap(childConfigFieldIdToObjMap);
        setFields(fieldsToRender);
    }, [user]);


    function getPopContent(accountObj: Record<string, any>, linkStatus: string): ReactElement {

        const options: any[] = [];

        /*
        options.push({
            optionIconSrc: '/images/newDeleteIcon.png',
            iconClassName: 'delete-img-style',
            iconContainerClassName: 'select-item-style',
            optionName: 'delete',
            optionAction: () => {
                const id = Helper.getUrlParam('id');
                if (id) {
                    dispatch(deleteAccountUserLink({
                        firebase,
                        reqObj: {
                            userId: user.id,
                            accountId: accountObj.id
                        }
                    })); 
                }
            }
        });
        */

        const lowercasedLinkStatus = linkStatus?.toLowerCase();

        if (lowercasedLinkStatus === 'linked') {
            options.push({
                optionIconSrc: '/images/unlink.png',
                iconClassName: 'link-img-style',
                iconContainerClassName: 'select-item-style',
                optionName: 'delink',
                optionAction: () => {
                    const id = Helper.getUrlParam('id');
                    if (id) {

                        let confirmAlertObject: any = Helper.getDefaultConfirmAlertObject('purple');

                        confirmAlertObject.yes = formatMsg("confirm");
                        confirmAlertObject.no = formatMsg("cancel");

                        confirmAlertObject.title = formatMsg('delinkAccountFromUser');
                        confirmAlertObject.message = (
                            <div className='flex-wrap'>
                                {formatMsg('areYouSureYouWantTo') + ' ' + formatMsg('small.delink') + ' '}<span className='font-weight-600'>{accountObj.name}</span>{' ' + formatMsg('small.account') + ' ' + formatMsg('from') + ' '}<span className='font-weight-600'>{user.name}</span><span>?</span>
                            </div>
                        )

                        confirmAlertObject.yesOnClick = () => {
                            dispatch(delinkAccountFromUser({
                                firebase,
                                reqObj: {
                                    userId: user.id,
                                    accountId: accountObj.id
                                }
                            }));
                        };

                        Helper.confirmAlert(confirmAlertObject);
                    }
                }
            })
        }
        else if (lowercasedLinkStatus === 'delinked') {
            options.push({
                optionIconSrc: '/images/link.png',
                iconClassName: 'unlink-img-style',
                iconContainerClassName: 'select-item-style',
                optionName: 'link',
                optionAction: () => {
                    const id = Helper.getUrlParam('id');
                    if (id) {

                        let confirmAlertObject: any = Helper.getDefaultConfirmAlertObject('purple');

                        confirmAlertObject.yes = formatMsg("confirm");
                        confirmAlertObject.no = formatMsg("cancel");

                        confirmAlertObject.title = formatMsg('linkAccountWithUser');
                        confirmAlertObject.message = (
                            <div className="flex-wrapp">
                                {formatMsg('areYouSureYouWantTo') + ' ' + formatMsg('small.link') + ' '}<span className='font-weight-600'>{accountObj.name}</span>{' ' + formatMsg('small.account') + ' ' + formatMsg('small.with') + ' '}<span className='font-weight-600'>{user.name}</span><span>?</span>
                            </div>
                        )

                        confirmAlertObject.yesOnClick = () => {
                            dispatch(linkAccountToUser({
                                firebase,
                                reqObj: {
                                    userId: user.id,
                                    accountId: accountObj.id
                                }
                            }));
                        };

                        Helper.confirmAlert(confirmAlertObject);
                    }
                }
            })
        }

        return (
            <div>
                {
                    options.map((optionObj, index) => {
                        return (
                            <div
                                onClick={optionObj.optionAction}
                                className={`options-list ${(index !== (options.length - 1)) ? 'border-bottom-gray' : ''}`}
                            >
                                <div
                                    className={optionObj.iconContainerClassName}
                                >
                                    <img
                                        className={optionObj.iconClassName}
                                        src={optionObj.optionIconSrc}
                                    />
                                </div>
                                <div className='global-regular-font'>
                                    {formatMsg(optionObj.optionName)}
                                </div>
                            </div>
                        );
                    })
                }
            </div>
        )
    }

    return (
        <div className="user-main-card">
            <div className="user-card-header" style={{ borderBottom: "0.5px solid #E0E0E0" }}>
                <LinkWrapper href={"/dashboard/users/userDetailView?id=" + user.id + "&entityType=user"}>
                    <div className="custom-user-card-heading">{user.name}</div>
                </LinkWrapper>
                {!Helper.isParent(firebase) && (user?.linkedAccountsIdAndStatusObjArr?.length > 0 && user.linkedAccountsIdAndStatusObjArr.some((obj) => obj.accountType?.toLowerCase() === 'primary_account' && obj.accountId === currentDetailedViewOfAccountId)) ?
                    <div>
                        <img src="/images/newEditImg.png" className="user-in-account-edit"
                            onClick={() => action()}
                        ></img>
                        <img src="/images/newDeleteIcon.png" className="user-in-account-delete"
                            onClick={() => {

                                let confirmAlertObject: any = Helper.getDefaultConfirmAlertObject();
                                
                                confirmAlertObject.yes = formatMsg("confirm");
                                confirmAlertObject.no = formatMsg("cancel");
                                
                                confirmAlertObject.title = formatMsg('deleteUser');
                                confirmAlertObject.message = (
                                    <div className="flex-wrapp">
                                        {formatMsg('areYouSureYouWantTo') + ' ' + formatMsg('small.delete') + ' '}<span className='font-weight-600'>{user.name}</span><span>?</span>
                                    </div>
                                );
                                
                                confirmAlertObject.yesOnClick = () => {
                                    deleteAction()
                                };
                                
                                Helper.confirmAlert(confirmAlertObject);
                            }}
                        ></img>
                    </div>
                    :
                    null
                }

            </div>
            <Row style={{ padding: 8 }}>
                {
                    fields?.map((item) => {
                        return (
                            <Col lg={5} md={12} sm={24} xs={24} className="user-field-style">
                                <LinkWrapper href={"/dashboard/users/userDetailView?id=" + user.id + "&entityType=user"}>
                                    <div className="user-label-container">
                                        {item === "name" &&
                                            <div className="account-name-column">
                                                <img className='user-profileIcon' src={Helper.getProfileImageUrl(user)}></img>
                                            </div>
                                        }
                                        <div>
                                            <div className="user-card-label-style">
                                                {
                                                    childConfigFieldIdToObjMap && childConfigFieldIdToObjMap.has(item)
                                                        ? childConfigFieldIdToObjMap.get(item).name
                                                        : formatMsg(item)
                                                }
                                            </div>
                                            <div className="user-card-value-style">
                                                {
                                                    (item === "status")
                                                        ? formatMsg(user[item])
                                                        : childConfigFieldIdToObjMap && childConfigFieldIdToObjMap.has(item)
                                                            ? childConfigFieldIdToObjMap.get(item).value || '-'
                                                            : user[item] || "-"
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </LinkWrapper>
                            </Col>
                        )
                    })
                }
            </Row>
            {
                showLinkedAccounts &&
                <div>
                    <Row className="user-linked-accounts-header">
                        <Col lg={5} md={12} sm={24} xs={24} className="user-linked-accounts-style">
                            {formatMsg("linkedAccounts")}
                        </Col>
                        <Col lg={5} md={12} sm={24} xs={24} className="user-linked-accounts-style">
                            {formatMsg("email")}
                        </Col>
                        <Col lg={5} md={12} sm={24} xs={24} className="user-linked-accounts-style">
                            {formatMsg("phoneNumber")}
                        </Col>
                        <Col lg={5} md={12} sm={24} xs={24} className="user-linked-accounts-style">
                            {formatMsg("status")}
                        </Col>
                    </Row>
                    {user?.linkedAccountsIdAndStatusObjArr
                        ?.map(({ accountId, linkStatus, accountType }) => {
                            let accountObj = accounts?.find((item) => item.id === accountId);
                            if (accountObj) {
                                return (
                                    <Row className="user-linked-account-row">
                                        <Col lg={5} md={12} sm={24} xs={24} className="user-linked-accounts-style">
                                            <div className="account-name-column">
                                                <img className='account-profileIcon' src={Helper.getProfileImageUrl(accountObj)}></img>
                                                <div className="title-container">
                                                    <div className="global-subheader-regular">{accountObj.name}</div>
                                                    <div className="global-subheader-regular" style={{ color: colors.color_purple, fontSize: 'var(--font-size-14)' }}>{accountType && formatMsg(accountType)}</div>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col lg={5} md={12} sm={24} xs={24} className="user-linked-accounts-style">
                                            {accountObj.email}
                                        </Col>
                                        <Col lg={5} md={12} sm={24} xs={24} className="user-linked-accounts-style">
                                            {accountObj.phoneNumber}
                                        </Col>
                                        <Col lg={5} md={12} sm={24} xs={24} className="user-linked-accounts-style">
                                            {linkStatus && formatMsg(linkStatus)}
                                        </Col>
                                        {
                                            (accountType?.toLowerCase() !== 'primary_account')
                                                ? (<Col>
                                                    <div className="pop-content-column">
                                                        <Popover
                                                            open={
                                                                (clickedAccountId && clickedUserId) ?
                                                                    (clickedAccountId === accountObj.id && clickedUserId === user.id)
                                                                    :
                                                                    false
                                                            }
                                                            onOpenChange={(boolVal) => {
                                                                setClickedAccountId(boolVal ? accountObj.id : '');
                                                                setClickedUserId(boolVal ? user.id : '');
                                                            }}
                                                            content={getPopContent(accountObj, linkStatus)}
                                                            trigger='click'
                                                            placement='bottomRight'
                                                            id='custom-pop-over-style'
                                                        >
                                                            <div
                                                                className='three-dots-div'
                                                            >
                                                                <img
                                                                    className='three-dots'
                                                                    src='/images/threeDots.png'
                                                                />
                                                            </div>
                                                        </Popover>
                                                    </div>
                                                </Col>)
                                                : null
                                        }
                                    </Row>
                                )
                            }

                        })}
                </div>}
            <Row className="user-hideshow-linked-accounts"
                style={{ color: colors.v2_Cerulean }}
                onClick={() => setShowLinkedAccounts(!showLinkedAccounts)}>
                {showLinkedAccounts ? formatMsg("hideLinkedAccounts") : formatMsg("viewLinkedAccounts")}
                {showLinkedAccounts ?
                    <UpOutlined
                        style={{ marginLeft: 10, marginTop: 3 }}
                    /> :
                    <DownOutlined
                        style={{ marginLeft: 10, marginTop: 3 }}
                    />}
            </Row>
        </div>
    )
}
export default UserCard;