import React from "react";
import { Button } from "antd";
import colors from "../../libs/colorFactory";
import webFont from "../../libs/fonts";
import Helper from "../../util/helper";
interface PropTypes {
  borderRadius?: string | number;
  marginRight?: string | number;
  marginBottom?: string | number;
  marginLeft?: string | number;
  marginTop?: string | number;
  fontWeight?: string | number;
  fontSize?: string | number;
  fontColor?: string;
  backgroundColor?: string;
  border?: boolean;
  borderColor?: string;
  paddingLeft?: string | number;
  paddingRight?: string | number;
  whiteSpace?: string;
  height?: string | number;
  width?: string | number;
  maxWidth?: string | number;
  prefixIcon?: any;
  imgSrc?: any;
  title: string | any;
  icon?: any;
  onClick?: any;
  imageStyle?: object;
  className?: string,
  prefixRight?: string,
  type?: any;
  htmlType?: any;
  boxShadow?: any;
}
const PrefixIconButton: React.FC<PropTypes> = (props) => {
  return (
    <div
      className={props.className ? props.className : ""}
      style={{ cursor: "pointer" }}
    >
      <Button
        onClick={(e) => (props.onClick ? props.onClick(e) : null)}
        // size={props.size ? "default" : "large"}
        style={{
          borderRadius: props.borderRadius ? props.borderRadius : 4,
          marginRight: props.marginRight ? props.marginRight : 8,
          marginBottom: props.marginBottom ? props.marginBottom : undefined,
          marginLeft: props.marginLeft ? props.marginLeft : null,
          marginTop: props.marginTop ? props.marginTop : null,
          fontWeight: props.fontWeight ? props.fontWeight : null,
          fontSize: props.fontSize ? Helper.getClampCss(props.fontSize) : webFont.medium,
          color: props.fontColor ? props.fontColor : colors.color_mediumGrey,
          backgroundColor: props.backgroundColor ? props.backgroundColor : colors.color_white,
          border: props.border ? "1px solid" : "none",
          borderColor: props.borderColor ? props.borderColor : "none",
          paddingLeft: props.paddingLeft ? props.paddingLeft : "none",
          paddingRight: props.paddingRight ? props.paddingRight : "none",
          whiteSpace: props.whiteSpace ? props.whiteSpace : "none",
          height: props.height ? props.height : "auto",
          width: props.width ? props.width : "none",
          maxWidth: props.maxWidth ? props.maxWidth : "none",
          boxShadow: props.boxShadow ? props.boxShadow : "none"
        }}
        type={props.type ? props.type : null}
        htmlType={props.htmlType ? props.htmlType : null}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            // textTransform: props.textTransform ? props.textTransform : undefined,
          }}
        // style={{

        //   ...styles.inline,
        //   ...{
        //     textTransform: props.textTransform ? props.textTransform : undefined,
        //   },
        // }}
        >
          {props.imgSrc ? (
            <img
              src={props.imgSrc}
              alt="icon"
              style={
                {
                  height: 20,
                  width: 20,
                  marginRight: 8,
                  objectFit: "contain",
                }
                // props.imageStyle
                //   ? {
                //     ...props.imageStyle,
                //     ...{
                //       marginLeft: 5,
                //     },
                //   }
                //   : {
                //     ...styles.image,
                //     ...{
                //       marginLeft: 5,
                //     },
                //   }
              }
            />
          ) : null}
          {props.prefixIcon ? (
            <div style={{ marginRight: props.prefixRight || 3 }}>
              {props.prefixIcon}
            </div>
          ) : null}
          <div className="global-button-text">{props.title}</div>
          {props.icon ? (
            props.icon
          ) : null}
        </div>
      </Button>
    </div>
  );
};
export default PrefixIconButton;

