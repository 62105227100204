import "firebase/compat/storage";
import { call, takeLatest, put, take } from "redux-saga/effects";
import { callApi } from "../../util/superAgentUtil";
import actions from "../actionType";
import Helper from "../../util/helper";
import { notification } from "../../util/notification";
import formatMsg from "../../util/formatMsg";

function* getAllAccounts(request) {
    try {
        const { firebase } = request.payload;
        let endpoint = `bookingApi/account/all`;
        let response = yield call(callApi, firebase, "get", endpoint, {});
        if (response && response.status == 200) {
            yield put({ type: actions.ACCOUNTS_SAGA_SUCCESS, payload: { isLoading: false, accountList: response.body.data } });
        }
        else {
            yield put({ type: actions.ACCOUNTS_SAGA_FAILED });
        }
    } catch (err) {
        Helper.notifyBugsnag(err, true);
        yield put({ type: actions.REQUEST_FAILED });
        console.log("failed to fetch accounts", err);
    }
}
function* getAccountDetails(request) {
    try {
        const { firebase, id } = request.payload;
        let endpoint = `bookingApi/account/${id}`;
        let response = yield call(callApi, firebase, "get", endpoint, {});
        if (response && response.status == 200) {
            yield put({ type: actions.ACCOUNTS_SAGA_SUCCESS, payload: { isLoading: false, accountDetails: response.body.data } });
        }
        else {
            yield put({ type: actions.ACCOUNTS_SAGA_FAILED });
        }
    } catch (err) {
        Helper.notifyBugsnag(err, true);
        yield put({ type: actions.REQUEST_FAILED });
        console.log("failed to fetch account's details", err);
    }
}

function* createAccount(request) {
    try {
        const { firebase, reqBody, noNotif } = request.payload;
        let storagePath = firebase.sbp + "/media/images/";
        if (reqBody.profileImageUrl && typeof reqBody.profileImageUrl !== 'string') {
            let photourl = yield call(Helper.getAttachedMediaPath, storagePath, reqBody.profileImageUrl, firebase);
            if (photourl) {
                reqBody.profileImageUrl = photourl[0].path
            }
        }
        let endpoint
        let isUpdated = reqBody.id ? true : false;
        if (reqBody.id) {
            endpoint = `bookingApi/account/${reqBody.id}`;
            delete reqBody.id;
        } else {
            endpoint = `bookingApi/account`;
        }
        let response = yield call(callApi, firebase, "post", endpoint, reqBody);
        if (response && response.status == 200) {
            yield put({ type: actions.ACCOUNTS_SAGA_SUCCESS, payload: { isLoading: false, operationType: isUpdated ? "ACCOUNT_UPDATED" : "ACCOUNT_CREATED" } });
            if (!noNotif) {
                notification("success", isUpdated ? formatMsg("updateAccount.success") : formatMsg("createAccount.success"))
            }
        }
        else {
            yield put({ type: actions.ACCOUNTS_SAGA_FAILED });
            notification("success", formatMsg("createAccount.error"))
        }
    } catch (err) {
        Helper.notifyBugsnag(err, true);
        yield put({ type: actions.REQUEST_FAILED });
        console.log("failed to create account", err);
    }
}

function* linkAccount(request) {
    try {
        const { firebase, reqObj } = request.payload;
        let endpoint = `bookingApi/account/linkAccounts`;
        let response = yield call(callApi, firebase, "post", endpoint, reqObj);
        if (response && response.status == 200) {
            yield put({ type: actions.ACCOUNTS_SAGA_SUCCESS, payload: { isLoading: false, operationType: "ACCOUNTS_LINKED" } });
            notification("success", formatMsg("accountsLinked.success"));
        }
        else {
            yield put({ type: actions.ACCOUNTS_SAGA_FAILED });
        }
    } catch (err) {
        Helper.notifyBugsnag(err, true);
        yield put({ type: actions.ACCOUNTS_SAGA_FAILED });
        console.log("failed to link accounts", err);
    }
}

function* deleteAccount(request) {
    try {
        const { firebase, id } = request.payload;
        let endpoint = `bookingApi/account/${id}`;
        let response = yield call(callApi, firebase, "delete", endpoint, {});
        if (response && response.status == 200) {
            yield put({ type: actions.ACCOUNTS_SAGA_SUCCESS, payload: { isLoading: false, operationType: "ACCOUNT_DELETED" } });
            notification("success", formatMsg("deleteAccount.success"))
        }
        else {
            yield put({ type: actions.ACCOUNTS_SAGA_FAILED });
        }
    } catch (err) {
        Helper.notifyBugsnag(err, true);
        yield put({ type: actions.ACCOUNTS_SAGA_FAILED });
        console.log("failed to delete user", err);
    }
}


function* getAccountsListByDateRange(request) {
    try {
        const { firebase, startDate, endDate } = request.payload;
        let endpoint = `bookingApi/account/listByDateRange?startDate=${startDate}&endDate=${endDate}`;
        let response = yield call(callApi, firebase, "get", endpoint, {}, null, null, true);
        if (response && response.status == 200) {
            yield put({ type: actions.ACCOUNTS_SAGA_SUCCESS, payload: { isLoading: false, dateRangedAccountsList: response.body.data } });
        }
        else {
            yield put({ type: actions.ACCOUNTS_SAGA_FAILED });
        }
    } catch (err) {
        Helper.notifyBugsnag(err, true);
        yield put({ type: actions.REQUEST_FAILED });
        console.log("failed to fetch accounts", err);
    }
}


function* delinkAccount(request) {
    try {
        const { firebase, reqObj } = request.payload;
        let endpoint = `bookingApi/account/delinkAccounts`;
        let response = yield call(callApi, firebase, "post", endpoint, reqObj);
        if (response && response.status == 200) {
            yield put({ type: actions.ACCOUNTS_SAGA_SUCCESS, payload: { isLoading: false, operationType: "ACCOUNTS_DELINKED" } });
            notification("success", formatMsg("accountsDelinked.success"));
        }
        else {
            yield put({ type: actions.ACCOUNTS_SAGA_FAILED });
        }
    } catch (err) {
        Helper.notifyBugsnag(err, true);
        yield put({ type: actions.ACCOUNTS_SAGA_FAILED });
        console.log("failed to link accounts", err);
    }
}


function* deleteLinkedAccount(request) {
    try {
        const { firebase, reqObj } = request.payload;
        let endpoint = `bookingApi/account/deleteLinkedAccount`;
        let response = yield call(callApi, firebase, "post", endpoint, reqObj);
        if (response && response.status == 200) {
            yield put({ type: actions.ACCOUNTS_SAGA_SUCCESS, payload: { isLoading: false, operationType: "LINKED_ACCOUNT_DELETED" } });
            notification("success", formatMsg("linkedAccountDeleted.success"));
        }
        else {
            yield put({ type: actions.ACCOUNTS_SAGA_FAILED });
        }
    } catch (err) {
        Helper.notifyBugsnag(err, true);
        yield put({ type: actions.ACCOUNTS_SAGA_FAILED });
        console.log("failed to link accounts", err);
    }
}



export default function* rootSaga() {
    yield takeLatest(actions.ACCOUNTS_GET_ALL_ACCOUNTS, getAllAccounts);
    yield takeLatest(actions.ACCOUNTS_GET_ACCOUNT_DETAILS, getAccountDetails);
    yield takeLatest(actions.ACCOUNTS_CREATE_ACCOUNT, createAccount);
    yield takeLatest(actions.ACCOUNTS_LINK_ACCOUNT, linkAccount);
    yield takeLatest(actions.ACCOUNTS_DELETE_ACCOUNT, deleteAccount);
    yield takeLatest(actions.ACCOUNTS_GET_LIST_BY_DATE_RANGE, getAccountsListByDateRange);
    yield takeLatest(actions.ACCOUNTS_DELINK_ACCOUNT, delinkAccount);
    yield takeLatest(actions.ACCOUNTS_DELETE_LINKED_ACCOUNT, deleteLinkedAccount);
}