import "firebase/compat/storage";
import { call, takeLatest, put, take } from "redux-saga/effects";
import { callApi } from "../../util/superAgentUtil";
import actions from "../actionType";
import Helper from "../../util/helper";
import { notification } from "../../util/notification";
import formatMsg from "../../util/formatMsg";

function* getAllUsers(request) {
    try {
        const { firebase } = request.payload;
        let endpoint = `bookingApi/user/all`;
        let response = yield call(callApi, firebase, "get", endpoint, {});
        if (response && response.status == 200) {
            yield put({ type: actions.USERS_SAGA_SUCCESS, payload: { isLoading: false, userList: response.body.data } });
        }
        else {
            yield put({ type: actions.USERS_SAGA_FAILED });
        }
    } catch (err) {
        Helper.notifyBugsnag(err, true);
        yield put({ type: actions.REQUEST_FAILED });
        console.log("failed to fetch users", err);
    }
}
function* getUserDetails(request) {
    try {
        const { firebase, id } = request.payload;
        let endpoint = `bookingApi/user/${id}`;
        let response = yield call(callApi, firebase, "get", endpoint, {});
        if (response && response.status == 200) {
            yield put({ type: actions.USERS_SAGA_SUCCESS, payload: { isLoading: false, userDetails: response.body.data } });
        }
        else {
            yield put({ type: actions.USERS_SAGA_FAILED });
        }
    } catch (err) {
        Helper.notifyBugsnag(err, true);
        yield put({ type: actions.REQUEST_FAILED });
        console.log("failed to user's details", err);
    }
}


function* createUser(request) {
    try {
        const { firebase, reqBody } = request.payload;
        let storagePath = firebase.sbp + "/media/images/";
        if (reqBody.profileImageUrl && typeof reqBody.profileImageUrl !== 'string') {
            let photourl = yield call(Helper.getAttachedMediaPath, storagePath, reqBody.profileImageUrl, firebase);
            if (photourl) {
                reqBody.profileImageUrl = photourl[0].path
            }
        }
        let endpoint
        let isUpdated = reqBody.id ? true : false;
        if (reqBody.id) {
            endpoint = `bookingApi/user/${reqBody.id}`;
        } else {
            endpoint = `bookingApi/user`;
        }
        let response = yield call(callApi, firebase, "post", endpoint, reqBody);
        if (response && response.status == 200) {

            const payload: Record<string, any> = {
                isLoading: false, 
                operationType: (isUpdated) ? "USER_UPDATED" : "USER_CREATED", 
            }

            if (!isUpdated) {
                payload.lastCreatedUser = response.body.data.user; 
            }

            yield put({ 
                type: actions.USERS_SAGA_SUCCESS, 
                payload 
            }); 

            notification("success", isUpdated ? formatMsg("updateUser.success") : formatMsg("createUser.success"))
        }
        else {
            yield put({ type: actions.USERS_SAGA_FAILED });
            notification("success", formatMsg("createUser.error"))
        }
    } catch (err) {
        Helper.notifyBugsnag(err, true);
        yield put({ type: actions.USERS_SAGA_FAILED });
        console.log("failed to create user", err);
    }
}

function* deleteUser(request) {
    try {
        const { firebase, id } = request.payload;
        let endpoint = `bookingApi/user/${id}`;
        let response = yield call(callApi, firebase, "delete", endpoint, {});
        if (response && response.status == 200) {
            yield put({ type: actions.USERS_SAGA_SUCCESS, payload: { isLoading: false, operationType: "USER_DELETED" } });
            notification("success", formatMsg("deleteUser.success"))
        }
        else {
            yield put({ type: actions.USERS_SAGA_FAILED });
        }
    } catch (err) {
        Helper.notifyBugsnag(err, true);
        yield put({ type: actions.REQUEST_FAILED });
        console.log("failed to delete user", err);
    }
}


function* getUsersListByDateRange(request) {
    try {
        const { firebase, startDate, endDate } = request.payload;
        let endpoint = `bookingApi/user/listByDateRange?startDate=${startDate}&endDate=${endDate}`;
        let response = yield call(callApi, firebase, "get", endpoint, {}, null, null, true);
        if (response && response.status == 200) {
            yield put({ type: actions.USERS_SAGA_SUCCESS, payload: { isLoading: false, dateRangedUsersList: response.body.data } });
        }
        else {
            yield put({ type: actions.USERS_SAGA_FAILED });
        }
    } catch (err) {
        Helper.notifyBugsnag(err, true);
        yield put({ type: actions.REQUEST_FAILED });
        console.log("failed to fetch users by date range", err);
    }
}


function* linkAccountToUser(request) {
    try {
        const { firebase, reqObj } = request.payload;
        let endpoint = `bookingApi/user/linkAccountToUser`;
        let response = yield call(callApi, firebase, "post", endpoint, reqObj);
        if (response && response.status == 200) {
            yield put({ 
                type: actions.USERS_SAGA_SUCCESS, 
                payload: { isLoading: false, operationType: "LINKED_ACCOUNT_TO_USER" } 
            }); 
            notification("success", formatMsg("linkedAccountToUser.success")); 
        }
        else {
            yield put({ type: actions.USERS_SAGA_FAILED });
        }
    } catch (err) {
        Helper.notifyBugsnag(err, true);
        yield put({ type: actions.USERS_SAGA_FAILED });
        console.log("Failed to link account to user", err);
    }
}


function* deleteAccountUserLink(request) {
    try {
        const { firebase, reqObj } = request.payload;
        let endpoint = `bookingApi/user/deleteAccountUserLink`;
        let response = yield call(callApi, firebase, "post", endpoint, reqObj);
        if (response && response.status == 200) {
            yield put({ type: actions.USERS_SAGA_SUCCESS, payload: { isLoading: false, operationType: "DELETED_ACCOUNT_USER_LINK" } });
            notification("success", formatMsg("deletedAccountUserLink.success"));
        }
        else {
            yield put({ type: actions.USERS_SAGA_FAILED });
        }
    } catch (err) {
        Helper.notifyBugsnag(err, true);
        yield put({ type: actions.USERS_SAGA_FAILED });
        console.log("failed to delete account user link", err);
    }
}


function* delinkAccountFromUser(request) {
    try {
        const { firebase, reqObj } = request.payload;
        let endpoint = `bookingApi/user/delinkAccountFromUser`;
        let response = yield call(callApi, firebase, "post", endpoint, reqObj);
        if (response && response.status == 200) {
            yield put({ type: actions.USERS_SAGA_SUCCESS, payload: { isLoading: false, operationType: "DELINKED_ACCOUNT_AND_USER" } });
            notification("success", formatMsg("delinkedAccountAndUser.success"));
        }
        else {
            yield put({ type: actions.USERS_SAGA_FAILED });
        }
    } catch (err) {
        Helper.notifyBugsnag(err, true);
        yield put({ type: actions.USERS_SAGA_FAILED });
        console.log("failed to delink account and user", err);
    }
}


function* linkNewUserToLinkedAccounts(request) {
    try {
        const { firebase, reqObj } = request.payload;
        let endpoint = `bookingApi/user/linkNewUserToLinkedAccounts`;

        let response = yield call(callApi, firebase, "post", endpoint, reqObj);
        
        if (response && response.status == 200) {
            yield put({ 
                type: actions.USERS_SAGA_SUCCESS, 
                payload: { 
                    isLoading: false, 
                    operationType: "LINKED_NEW_USER_TO_SELECTED_LINKED_ACCOUNTS" 
                } 
            }); 
            notification("success", formatMsg("linkedNewUserToSelectedLinkedAccounts.success")); 
        }
        else {
            yield put({ type: actions.USERS_SAGA_FAILED });
        }
    } 
    catch (err) {
        Helper.notifyBugsnag(err, true);
        yield put({ type: actions.USERS_SAGA_FAILED });
        console.log("failed to delink account and user", err);
    }
}


export default function* rootSaga() {
    yield takeLatest(actions.USERS_GET_ALL_USERS, getAllUsers);
    yield takeLatest(actions.USERS_GET_USER_DETAILS, getUserDetails);
    yield takeLatest(actions.USERS_CREATE_USER, createUser);
    yield takeLatest(actions.USERS_DELETE_USER, deleteUser);
    yield takeLatest(actions.USERS_GET_LIST_BY_DATE_RANGE, getUsersListByDateRange);
    yield takeLatest(actions.USERS_DELETE_ACCOUNT_USER_LINK, deleteAccountUserLink);
    yield takeLatest(actions.USERS_DELINK_ACCOUNT_FROM_USER, delinkAccountFromUser);
    yield takeLatest(actions.USERS_LINK_ACCOUNT_TO_USER, linkAccountToUser);
    yield takeLatest(actions.USERS_LINK_NEW_USER_TO_LINKED_ACCOUNTS, linkNewUserToLinkedAccounts);
}