import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isLoading: false,
  operationType: undefined,
  userSetting: undefined,
  guestSetting: undefined,
  customFields: [],
  customFieldsIdToObjMap: new Map(),
  defaultFields: [],
  defaultFieldsIdToObjMap: new Map(), 
  defaultFieldsNameToObjMap: new Map(), 
  userTypesList: [], 
  userTypeIdToObjMap: new Map(), 
  selectedUserTypeObj: undefined  
}

export const settingSlice = createSlice({
  name: 'setting',
  initialState: { ...initialState },
  reducers: {
    sagaSuccess: (state, action) => {
      if (action.payload) {
        Object.assign(state, action.payload);
      }
    },
    sagaFailed: (state, action) => {
      if (action.payload) {
        Object.assign(state, action.payload);
      }
      else state.operationType = undefined; 
      state.isLoading = false;
    },
    addClassroom: (state, action) => {
      state.isLoading = true
    },
    deleteClassroom: (state, action) => {
      state.isLoading = true
    },
    addSettings: (state, action) => {
      state.isLoading = true
    },
    deleteSettings: (state, action) => {
      state.isLoading = true
    },
    confirmSettings: (state, action) => {
      state.isLoading = true
    },
    getUserSettings: (state, action) => {
      state.isLoading = true
    },
    getGuestSettings: (state, action) => {
      state.isLoading = true
    },
    createStudentCustomField: (state, action) => {
      state.isLoading = true
    },
    getCustomFields: (state, action) => {
      state.isLoading = true
    },
    deleteCustomField: (state, action) => {
      state.isLoading = true
    },
    saveOrderChangesInDB: (state, action) => {
      state.isLoading = true
    },
    getUserTypesList: (state, action) => {
      state.isLoading = true
    }, 
    createNewUserType: (state, action) => {
      state.isLoading = true 
    }, 
    updateSelectedUserType: (state, action) => {
      state.isLoading = true 
    }, 
    updateSchoolConfigInMasterDB: (state, action) => {
      state.isLoading = true 
    } 
  }
})

export const {
  sagaSuccess,
  addClassroom,
  deleteClassroom,
  addSettings,
  deleteSettings,
  confirmSettings,
  getUserSettings,
  getGuestSettings,
  createStudentCustomField,
  getCustomFields,
  deleteCustomField,
  saveOrderChangesInDB,
  getUserTypesList, 
  createNewUserType, 
  updateSelectedUserType, 
  updateSchoolConfigInMasterDB  
} = settingSlice.actions;

export default settingSlice.reducer