"use client";
import styled from 'styled-components';
import { Table, Card, Collapse, Modal, Button } from 'antd';


const PageHeaderWrapper = styled.div`
display:flex;
justify-content:space-between;
align-items:center;
margin-bottom:15px;
flex-wrap:wrap;
`;

const ButtonHolder = styled.div`
display:flex;
justify-content:space-between;
align-items:center;
`;

const Box = styled.div`
padding:20px;
font-size:15px;

`

const TableWrapper = styled(Table)`
overflow:scroll;
border-radius:10px;
box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
border:0.5px solid #E8E8E8;

.ant-table {
  min-width: 1300px; // Set min-width for the inner table container
}

.ant-table-thead >tr>th{
    height: 28px !important;
    font-size: var(--font-size-16);
    font-weight: 500
}
.ant-table-cell::before {
    content: none !important;
  }

  .ant-table-cell{
    height: 60px;
    font-size: var(--font-size-15);
  }

  .ant-table-pagination-right{
    justify-content:center;
  }

  .ant-table-thead{
    border:1px solid red;
  }
`

const CardWrapper = styled(Card)`
box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
border:0.5px solid #E8E8E8;
object-bit:contain;
word-wrap:break-word;
word-break: break-all;
margin-bottom:20px;
color: #333;
.ant-card-body{
  padding:18px
}
`

const CollapseWrapper = styled(Collapse)`
margin:16px;
.ant-collapse-header{
  border-bottom:1px solid #D0D0D0;
  border-radius: 0px !important;
  font-size: var(--font-size-18) !important;
  font-weight: 700 !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
}


`;

const ModalWrapper = styled(Modal)`
.ant-modal-title{
  border-bottom:1px solid #D0D0D0;
  margin-left:-24px;
  margin-right:-24px;
  padding-left:24px;
  padding-right:24px;
  padding-bottom: 17px;
}
  
.ant-modal-body {
  max-height: calc(100vh - 280px); 
  overflow-y: auto; 
}

/* Ensure <ul> styles are applied properly */
.ant-modal-body ul {
  margin-left: 12px; /* Remove unwanted margins */
  /* Add default padding for lists */
  list-style-type: disc !important;
}

.ant-modal-body ul li {
  font-size:  var(--font-size-14); /* Adjust font size if needed */
  line-height: 1.5; /* Improve readability */
}

`

const PrimaryButtonWrapper = styled(Button)`
  background: #7413BD;
  color: white;
  border-radius: 5px;
  font-size: var(--font-size-14);
  font-weight: 600;
`


export {
  PageHeaderWrapper,
  Box,
  TableWrapper,
  CardWrapper,
  CollapseWrapper,
  ButtonHolder,
  ModalWrapper,
  PrimaryButtonWrapper
};