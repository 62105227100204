import React from "react";
import { Layout } from "antd";
const LayoutWrapper: React.FC<any> = ({ children }) => {
    return (
        <Layout
            style={{
                height: "94vh",
                background: "white"
            }}
        >
            {children}
        </Layout>

    )
}
export default LayoutWrapper;