const prodConfig = {
    apiKey: "AIzaSyAC7azjLKOSjvAOIy4S_ccDB_O8age4KMo",
    authDomain: "masterbooking-f8b6e.firebaseapp.com",
    projectId: "masterbooking-f8b6e",
    storageBucket: "masterbooking-f8b6e.appspot.com",
    messagingSenderId: "635424051725",
    appId: "1:635424051725:web:e37afd337802cabda83b44",
    measurementId: "G-46V2LM255Q"
};
const devConfig = {
    apiKey: "AIzaSyD8PWEqWcq2Z6IHRYfThZely1T5T3_PnL8",
    authDomain: "masterbookingdev.firebaseapp.com",
    projectId: "masterbookingdev",
    storageBucket: "masterbookingdev.appspot.com",
    messagingSenderId: "177926895397",
    appId: "1:177926895397:web:953d4c67c5c900c96e8ccc",
    measurementId: "G-NSEHLXRGY0"
};
const stagingConfig = {
    apiKey: "AIzaSyDY0RMMViKDxY-aogKwjFs16uiEei2TP7Y",
    authDomain: "masterstaging.firebaseapp.com",
    databaseURL: "https://masterstaging-default-rtdb.firebaseio.com",
    projectId: "masterstaging",
    storageBucket: "masterstaging.appspot.com",
    messagingSenderId: "787189333220",
    appId: "1:787189333220:web:1d7266ae1c6c9a8e57c0f1",
    measurementId: "G-QNGJ8V6HJ6",
};

export const localDevPointUrl = "http://127.0.0.1:5001/masterbookingdev/us-central1/";
export const localMasterEndPointUrl = "http://127.0.0.1:5001/masterbooking-f8b6e/us-central1/";
export const devEndPointUrl = "https://us-central1-masterbookingdev.cloudfunctions.net/";
export const prodEndPointUrl = "https://us-central1-masterbooking-f8b6e.cloudfunctions.net/";
export const StagingEndPointUrl = "https://us-central1-masterstaging.cloudfunctions.net/";


export let endPoint;

switch (process.env.NODE_ENV) {
    case "production":
        endPoint = "production";
        if (process.env.NEXT_PUBLIC_ENDPOINT && process.env.NEXT_PUBLIC_ENDPOINT !== "production") {
            endPoint = process.env.NEXT_PUBLIC_ENDPOINT;
        }
        break;
    case "development":
        endPoint = process.env.NEXT_PUBLIC_ENDPOINT;
        break;

    case "test":
        endPoint = "test";
        break;

    default:
        endPoint = "development";
}


export const listingEndPointUrl =
    endPoint === "production" ? "https://listing.mybookingo.com/" : "https://dev-listing-mybookingo.web.app/";


export const endPointUrl =
    endPoint === "production"
        ? prodEndPointUrl
        : endPoint === "staging"
            ? StagingEndPointUrl
            : endPoint === "localDev"
                ? localDevPointUrl
                : endPoint === "localProd" ? localMasterEndPointUrl : devEndPointUrl;

export const firebaseConfig =
    endPoint === "production"
        ? prodConfig
        : endPoint === "staging"
            ? stagingConfig
            : endPoint === "localProd" ? prodConfig : devConfig;



export const oktaRedirectUrl = endPoint === "production"
    ?
    "https://app.mybookingo.com"
    // "http://localhost:3000"
    :
    endPoint === "localDev" || endPoint === "localProd" ?
        "http://localhost:3000" :
        endPoint === "staging"
            ? ""
            : endPoint === "development" ?
                "https://dev.mybookingo.com"
                //"http://localhost:3000"
                :
                "http://localhost:3000";



export const authorizationParamsConfig: any = {
    redirect_uri: oktaRedirectUrl,
    audience: "illumine/auth",
}



export const auth0ClientConfig: any = {
    domain: 'dev-pz1otrtbq4knzyul.us.auth0.com',
    clientId: 'AwBDpnWD2v5Ex2GDLQ3ChbTgPv0dBBgc',
    authorizationParamsConfig,
    useRefreshTokens: true,
    cacheLocation: 'localstorage',
}
