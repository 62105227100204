"use client";
import SpinWrapper from "../SpinWrapper/page";
import PrefixIconButton from "../buttons/prefixIconButton";
import "./style.css";
import UserCard from "../userCard/page";
import formatMsg from "../../util/formatMsg";
import colors from "../../libs/colorFactory";
import { PlusOutlined } from "@ant-design/icons";
import { Row, Form } from "antd";
import { ModalWrapper } from "../wrapperComponents/page";
import FormWrapper from "../formWrapper/page";
import { useEffect, useState } from "react";
import { useDispatch } from 'react-redux';
import { notification } from "../../util/notification";
import { createUser, resetOperationType, deleteUser, linkNewUserToLinkedAccounts } from '../../redux/users/reducer'
import Helper from "../../util/helper";
import { settingActions } from "sharedresources"; 
import EmptyPage from "../EmptyPage/page";
import LinkUserToOtherLinkedAccounts from "./LinkUserToOtherLinkedAccounts";
import { useForm } from "antd/es/form/Form";
const { getCustomFields } = settingActions;


const AccountUsers: React.FC<any> = (props) => {
    const { accountDetails, isLoading, firebase } = props;
    const [addNewVisible, setAddnewVisible] = useState<any>(false);
    const [editableRecords, setEditableRecords] = useState<any>();
    const [currAccountObj, setCurrAccountObj] = useState<any>();
    const [isLinkUserModalVisible, setIsLinkUserModalVisible] = useState<boolean>(false);
    const [form] = Form.useForm();
    const [form2] = useForm(); 
    const dispatch = useDispatch();

    const { userReducer, settingReducer, accountReducer } = Helper.getSelectorState(["userReducer", "settingReducer", "accountReducer"]); 

    useEffect(() => {
        accountDetails && setCurrAccountObj({
            id: accountDetails.id,
            name: accountDetails.name,
            email: accountDetails.email,
            phoneNumber: accountDetails.phoneNumber,
            profileImageUrl: accountDetails.profileImageUrl
        })
    }, [accountDetails])

    function handleCancel() {
        form.resetFields();
        setAddnewVisible(undefined);
        setEditableRecords(undefined); 
    }

    useEffect(() => {
        const { operationType } = userReducer; 
        if (operationType) {
            switch (operationType) {
                case "USER_CREATED": 
                    handleCancel(); 
                    if (
                        accountDetails && 
                        accountDetails.linkedAccounts && 
                        accountDetails.linkedAccounts.length > 0 
                    ) {
                        setIsLinkUserModalVisible(true); 
                    }
                    dispatch(resetOperationType(null)); 
                    break; 
                case "USER_UPDATED": 
                    handleCancel();
                    dispatch(resetOperationType(null));
                    break; 
            }
        }   
    }, [userReducer.operationType])

    function handleOk() {

        form.validateFields()
            .then((values) => {
                values.status = "ACTIVE";
                let acountId = Helper.getUrlParam("id");
                values.accountId = acountId;
                values.childConfig = settingReducer.customFields ? 
                    settingReducer.customFields.map((additionalFieldObj) => { 
                        const tempAdditionalFieldObj = {...additionalFieldObj}; 
                        tempAdditionalFieldObj.value = values[additionalFieldObj.name] ? values[additionalFieldObj.name] : null; 
                        delete values[additionalFieldObj.name]; 
                        return tempAdditionalFieldObj; 
                    }) 
                    : 
                    null; 

                values.id = editableRecords && editableRecords.id;
                if (!values.profileImageUrl && editableRecords && editableRecords.profileImageUrl) {
                    values.profileImageUrl = editableRecords.profileImageUrl
                }
                dispatch(createUser({ reqBody: values, firebase: firebase }));
            })
            .catch((error) => {
                notification("error", formatMsg("pleaseFillAllDetails"))
                console.error('Validation failed:', error);
            });
    }


    const userFields = [
        [{
            label: formatMsg("addProfilePhoto"),
            type: "media",
            name: "profileImageUrl",
            placeHolder: formatMsg("addProfilePhoto"),
            warningMessage: "Please select profile photo!",
            inputType: "picture",
            values: form.getFieldValue("profileImageUrl")
        }],
        [{
            label: formatMsg("name"),
            type: "input",
            name: "name",
            placeHolder: formatMsg("name"),
            required: true,
            warningMessage: "Please input name!",
            inputType: "string",
        }],
        // [{
        //     label: formatMsg("gender"),
        //     type: "select",
        //     name: "gender",
        //     placeHolder: formatMsg("gender"),
        //     required: true,
        //     warningMessage: "Please select gender!",
        //     inputType: "select",
        //     options: [{ key: 'MALE', label: formatMsg("MALE") }, { key: 'FEMALE', label: formatMsg("FEMALE") }, { key: 'PREFER_NOT_TO_SAY', label: formatMsg("PREFER_NOT_TO_SAY") }],
        //     optionKey: "key",
        //     optionLabel: "label"
        // }],
    ]; 

    function handleLinkUserOk() {

        form2.validateFields()
            .then((values) => {
                values.userId = userReducer.lastCreatedUser.id; 
                values.introducedByAccountId = Helper.getUrlParam('id') ? Helper.getUrlParam('id') : ''; 

                dispatch(linkNewUserToLinkedAccounts({ firebase, reqObj: { ...values } })); 

                form2.resetFields(); 
                setIsLinkUserModalVisible(false); 
            })
            .catch((error) => {
                notification("error", formatMsg("pleaseFillAllDetails"))
                console.error('Validation failed:', error);
            });
    }
    
    function handleLinkUserCancel() { 
        form2.resetFields(); 
        setIsLinkUserModalVisible(false); 
    }

    if (accountDetails && Object.keys(accountDetails).length) {
        return (
            <SpinWrapper spinning={isLoading || userReducer.isLoading || accountReducer.isLoading}>
                <Row typeof="flex" justify={"end"}>
                    <PrefixIconButton
                        title={formatMsg("new")}
                        backgroundColor={colors.v2_Cerulean}
                        fontColor={colors.color_primarytext}
                        width="100px"
                        height="32px"
                        prefixIcon={<PlusOutlined />}
                        onClick={() => {
                            setAddnewVisible(true)
                        }}
                        marginBottom={15}
                    />
                </Row>
                {
                    accountDetails?.users && accountDetails.users.length !== 0 ?
                        accountDetails.users.map((user, i) => {
                            return <UserCard
                                currentDetailedViewOfAccountId={accountDetails.id}
                                firebase={firebase}
                                user={user}
                                index={i}
                                accounts={[...accountDetails.linkedAccounts, { ...currAccountObj }]}
                                action={() => {
                                    setAddnewVisible(true);
                                    let editFormObj = Helper.getEntityObjForSetFieldsWithChildConfigPopulated(user); 
                                    form.setFieldsValue({ ...editFormObj });
                                    setEditableRecords({ ...user })
                                }}
                                deleteAction={() => {
                                    dispatch(deleteUser({ firebase, id: user.id }))
                                }}
                            /> 
                        })
                    :
                        <EmptyPage
                            mainHeading={Helper.getSelectedUserTypeName(firebase, formatMsg("noUsersFound"), 'No')} 
                        /> 
                }

                <ModalWrapper
                    open={addNewVisible}
                    title={formatMsg("userDetails")}
                    onOk={handleOk}
                    onCancel={handleCancel}
                    okText={formatMsg("submit")}
                    maskClosable={false}
                    confirmLoading={userReducer.isLoading}
                >
                    <Form
                        form={form}
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        initialValues={{ remember: true }}
                    // onValuesChange={(_, val) => {
                    //     handlePreviewObj(val)
                    // }}
                    >
                        <FormWrapper
                            formFields={[...userFields, ...Helper.getAdditionalFormFields(settingReducer?.customFields)]}
                            editableRecords={editableRecords}
                            setEditableRecords={setEditableRecords}
                        />
                    </Form>
                </ModalWrapper>

                <LinkUserToOtherLinkedAccounts 
                    form={form2} 
                    isLoading={userReducer.isLoading}
                    isLinkUserModalVisible={isLinkUserModalVisible} 
                    user={userReducer.lastCreatedUser || {}} 
                    linkedAccountsList={
                        (accountDetails && accountDetails.linkedAccounts && accountDetails.linkedAccounts.length > 0) 
                        ? accountDetails.linkedAccounts 
                        : []
                    } 
                    handleLinkUserOk={handleLinkUserOk} 
                    handleLinkUserCancel={handleLinkUserCancel} 
                />
            </SpinWrapper>
        )
    } else {
        return (<SpinWrapper spinning={true}><div></div></SpinWrapper>)
    }

}
export default AccountUsers;