'use client';
import { Row, Col } from "antd";
import "./style.css";
import PrefixIconButton from "../buttons/prefixIconButton";
import formatMsg from "../../util/formatMsg";
import colors from "../../libs/colorFactory";
import Helper from "../../util/helper";

const ProfileDetailCard: React.FC<any> = (props) => {
    const { title, fields, action, type, deleteAction } = props;
    const slices = Helper.getSelectorState(["students"]);
    const { firebase } = slices;

    return (
        <div className="main-card">
            <div className="card-header" style={{ borderBottom: type !== "parentCard" ? fields?.some((item) => item.value) ? undefined : undefined : "0.5px solid #E0E0E0", position: 'relative' }}>
                <div className="global-card-header">{title}</div>
                {!(Helper.isParent(firebase) && type === "admissionDetails") ? type !== "parentCard" ?
                    // fields?.some((item) => item.value) ?
                    <img src="/images/pencil.png" className="editIconStyleAbsolute"
                        onClick={() => action()}
                    ></img>
                    //     :
                    //     <PrefixIconButton
                    //         title={formatMsg("add")}
                    //         backgroundColor={colors.primaryBgColor}
                    //         fontColor={colors.color_primarytext}
                    //         width="100px"
                    //         height="32px"
                    //         onClick={() => action()}
                    //     />
                    :
                    fields?.id ?
                        <div className="actionStyle">
                            <img src="/images/pencil.png" className="editIconStyleAbsolute"
                                onClick={() => action()}
                            />
                            {!Helper.isParent(firebase) && <img src="/images/delete.png" className="deleteIconStyle"
                                onClick={() => deleteAction()}
                            />}
                        </div>
                        : null
                    : <></>
                }

            </div>
            {
                type === "parentCard" ?
                    fields?.id ?
                        <div className="parent-title-container">
                            <img src={Helper.getProfileImageUrl(fields)} className="card-profile-photo-style"></img>
                            <div className="pdc-name-style">
                                <div className="global-page-header parent-name-style">{fields.name}</div>
                                <div className="detail-style">
                                    <div>
                                        <div className="parent-label-style">{formatMsg("page.phoneNumber")}</div>
                                        <div className="parent-label-style">{formatMsg("placeholder.email")}</div>
                                        <div className="parent-label-style">{formatMsg("relation")}</div>

                                    </div>
                                    <div className="parent-value-container">
                                        <div className="parent-value-style">{(fields.phoneNumber || "-")}</div>
                                        <div className="parent-value-style">{fields.email || "-"}</div>
                                        <div className="parent-value-style student-profile-capitalise">{fields.relation || "-"}</div>
                                    </div>
                                </div>
                                {/* <div className="prefeeredName-style">{studentObj.preferredName}</div>
                                    <div className="status-style">{Helper.getStatustag(studentObj.status)}</div> */}
                            </div>
                        </div>
                        :
                        <div className="parentCard-container" onClick={() => action()}>
                            <img src="/images/add.png" className="addIconStyle"></img>
                            <div className="parent-value-style">{formatMsg("clickToAdd")}{" "}{title}</div>
                        </div> :
                    <Row style={{ padding: 8 }}>
                        {
                            fields?.map((item) => {
                                item.value = Array.isArray(item.value) ? item.value.join(', ') : item.value;
                                return (
                                    <Col lg={5} md={12} sm={24} xs={24} className="field-style">
                                        <div className="card-label-style">{formatMsg(item.key)}</div>
                                        <div className="card-value-style">{item.value || "-"}</div>
                                    </Col>
                                )
                            })
                        }
                    </Row>
            }
        </div>
    )
}
export default ProfileDetailCard;