import Bugsnag from "@bugsnag/js";
import "firebase/compat/storage";
import { all, call, fork, put, take, takeEvery, takeLatest } from "redux-saga/effects";
import dbRef from "../../firebase-api/dbRef";
import { loginApi } from "../../firebase-api/login";
import dayjs from "../../libs/day";
import { getItem, setItem } from "../../libs/encryptedStorage";
import fbRef from "../../libs/fbRef";
import authHelper from "../../okta/page";
import Helper from "../../util/helper";
import { callApi } from "../../util/superAgentUtil";
import actions from "../actionType";
import { notification } from "../../util/notification";
import formatMsg from "../../util/formatMsg";

export function* signIn(request) {
  let payload = request.payload;
  try {

    let password = payload.password;
    let user = payload.user;
    let email = user.email || user.name;
    if (!email) {
      return
    }

    yield call(loginApi.initMasterConfig);
    //*****************************************
    let authUser = yield call(loginApi.signInWithEmail);
    if (!user) {
      yield fork(showErrorMsg, "Failed to login. Auth user not found.");
      return;
    }

    let encodedEmail = Helper.getEncodedValue(email);
    setItem("loggedInEncodedEmail", encodedEmail);
    //*****************************************
    yield call(loginApi.triggerAuthStateListener);

    //*****************************************
    yield put({ type: actions.AUTH_SUCCESS, payload: { authSuccess: true } });

    //*****************************************

  } catch (err) {
    Helper.notifyBugsnag(err, true);
    console.log("Failure in login flow", err);
    yield fork(logout);
  }
}

function* fetchNewUsers(reqObj: any) {
  try {
    const { email } = reqObj.payload;
    // let encodedEmail = Helper.getEncodedValue(email);
    let endpoint = `bookingApi/signup/${email}`
    let response = yield call(callApi, undefined, "get", endpoint, {});
    if (response.status == 200) {
      if (response.body.data && response.body.data.length) {
        yield put({ type: actions.NEW_USER_SUCCESS, payload: { newUsers: response.body.data, operationType: "NEW_USER_SUCCESS" } });
      } else {
        yield put({ type: actions.NEW_USER_SUCCESS, payload: { newUsers: response.body.data, operationType: "NO_NEW_USER" } });

      }
    } else {
      yield fork(showErrorMsg, "Failed to login. New user not found.");
      return;
    }
  } catch (err) {
    Helper.notifyBugsnag(err, true);
    console.log("Failure in login flow", err);
    yield fork(logout);
  }

}

function* continueFromSecondaryDb(schoolConfig: any, newUser: any, encodedEmail: any, noChannel: any, updateLastLogin: boolean) {
  try {
    let secondaryApp = yield call(loginApi.initSecondaryDb, schoolConfig, newUser.centerId);
    yield call(loginApi.loginSecondaryDb, schoolConfig, secondaryApp);
    if (newUser.userType === "PARENT") {
      yield fork(parentLogin, secondaryApp, schoolConfig, newUser, encodedEmail, noChannel)
    }
    else {
      yield fork(teacherLogin, secondaryApp, schoolConfig, newUser, encodedEmail, noChannel, updateLastLogin)
    }

    addBasicDetailsToProject(newUser, schoolConfig);

  } catch (err) {
    Helper.notifyBugsnag(err, true);
    console.log("Failure in login flow", err);
    yield fork(logout);
  }
}

function* updateTeacherLastLogin(firebase) {
  try {
    let endpoint = "bookingApi/teacher/" + firebase.teacher.id + "/lastLogin";
    yield call(callApi, firebase, "post", endpoint, {});
  } catch (err) {
    Helper.notifyBugsnag(err, true);
    console.log(err)
  }
}

function* getAllStaff(request) {
  try {
    let { firebase } = request.payload;
    const updateTeacherChan = yield call(dbRef.getEntitiesByChannel, firebase, fbRef.lastUpdateTeacher);
    while (true) {
      let lastUpdateNotification = yield take(updateTeacherChan);
      let endpoint = "bookingApi/teacher?teacherId=" + firebase.teacher.id
      let response = yield call(callApi, firebase, "get", endpoint, {}, false, false, true);
      if (response.status == 200) {
        let staffs = response.body.data?.teachers.filter((item) => !item.deleted) || [];
        yield put({ type: actions.STAFF_REQ_SUCCESS, payload: { isLoading: false } });
        yield put({ type: actions.LOGIN_SAGA_SUCCESS, payload: { firebase: { ...firebase, teacherList: staffs } } });
        yield put({ type: actions.UPDATE_CHANNEL, payload: { key: "teacherChan", val: updateTeacherChan } });
        setItem("teacherList", staffs)
      }
      else {
        yield put({ type: actions.STAFF_REQUEST_FAILED });
      }
    }
  } catch (err) {
    Helper.notifyBugsnag(err, true);
    yield put({ type: actions.STAFF_REQUEST_FAILED });
    console.log("failed get all staff  ", err);
  } finally {
    console.log("teacher channel closed")
  }
}

function* getAllStudents(request) {
  try {
    let { firebase } = request.payload;
    const updateStudentChan = yield call(dbRef.getEntitiesByChannel, firebase, fbRef.lastUpdateStudent);
    while (true) {
      let lastUpdateStudent = yield take(updateStudentChan);
      let endpoint = "bookingApi/student/all/students?teacherId=" + firebase.teacher.id
      let response = yield call(callApi, firebase, "get", endpoint, {}, false, false, true);
      if (response.status == 200) {
        let students = response.body.data?.students.filter((item) => !item.deleted) || [];
        yield put({ type: actions.STUDENT_REQ_SUCCESS, payload: { students, isLoading: false } });
        yield put({ type: actions.UPDATE_CHANNEL, payload: { key: "studentChan", val: updateStudentChan } });
        setItem("studentList", students)
      }
      else {
        yield put({ type: actions.STAFF_REQUEST_FAILED });
      }
    }
  } catch (err) {
    Helper.notifyBugsnag(err, true);
    yield put({ type: actions.STAFF_REQUEST_FAILED });
    console.log("failed get all students  ", err);
  } finally {
    console.log("student channel closed")
  }
}

function* teacherLogin(secondaryApp, schoolConfig: { branchPath: any; }, newUser: any, encodedEmail: any, noChannel: any, updateLastLogin: boolean) {
  let centerIds = []
  if (newUser.centers)
    centerIds = newUser.centers.map(center => center.id);
  newUser.centerIds = centerIds;
  setItem("teacher", newUser);
  // let teacher = yield call(loginApi.fetchTeacher, secondaryApp, schoolConfig.branchPath, newUser.teacherId);

  // setItem("teacher", teacher);
  //*****************************************

  // let studentList = yield call(loginApi.fetchStudents, secondaryApp, schoolConfig.branchPath);
  // setItem("studentList", studentList);

  //*****************************************

  let firebaseObj = createFirebaseObj(secondaryApp, schoolConfig, newUser, { teacher: newUser });
  //*****************************************



  yield put({ type: actions.SIGN_SUCCESS, payload: firebaseObj });
  //*****************************************

  if (updateLastLogin) {
    yield updateTeacherLastLogin(firebaseObj);
  }

  yield all([
    call(getAllStudents, { payload: { firebase: firebaseObj } }),
    call(getAllStaff, { payload: { firebase: firebaseObj } })
  ]);



  if (!noChannel) {
    yield fork(enableNewUserChannel, encodedEmail);
    //*****************************************

    yield fork(enableSchoolConfigChannel, newUser.centerId);
  }
}

function* parentLogin(secondaryApp, schoolConfig: { branchPath: any; }, newUser: any, encodedEmail: any, noChannel: any) {
  let student = yield call(loginApi.fetchEntityByPromise, secondaryApp, schoolConfig.branchPath, fbRef.student, newUser.id);
  setItem("student", student);

  let parent = yield call(loginApi.fetchEntityByPromise, secondaryApp, schoolConfig.branchPath, fbRef.parents, newUser.parentId);

  let firebaseObj = createFirebaseObj(secondaryApp, schoolConfig, newUser, { student, parent });
  yield put({ type: actions.PARENT_SIGN_SUCCESS, payload: firebaseObj });

  if (!noChannel) {
    yield fork(enableNewUserChannel, encodedEmail);
    //*****************************************

    yield fork(enableSchoolConfigChannel, newUser.centerId);
  }
}

function addBasicDetailsToProject(newUser, schoolConfig) {
  try {
    Bugsnag.setUser(
      newUser.email,
      newUser.email,
      newUser.centerId,
    );

    if (schoolConfig.timezone) {
      dayjs.tz.setDefault(schoolConfig.timezone);
      // dayjs.tz.setDefault("Europe/Sofia");
      // console.log(dayjs().tz().format("h-ma"));
      // console.log(dayjs().tz("Europe/Sofia").format("h-ma"));
      // console.log("setted zone", dayjs.tz()["$x"]["$timezone"])
    }
    else {
      dayjs.tz.setDefault(dayjs.tz.guess());
    }
    if (dayjs.tz()["$x"] && dayjs.tz()["$x"]["$timezone"]) {
      console.log("setted timezone", dayjs.tz()["$x"]["$timezone"])
    }


  }
  catch (error) {
    Helper.notifyBugsnag(error, true);
    console.log("error to setup basic detail of user ", error);
  }

}

function createFirebaseObj(secondaryApp, schoolConfig, newUser, loggedInEntities) {
  return {
    secondaryApp: secondaryApp,
    secondaryDb: secondaryApp.database(),
    secondaryStorage: secondaryApp.storage(),
    sbp: schoolConfig.branchPath,
    sbDbName: schoolConfig.centerId,
    user: newUser,
    schoolConfig: schoolConfig,
    ...loggedInEntities
  }
}

function* showErrorMsg(msg) {
  alert(msg);
  yield fork(logout);
  console.log("error", msg);
}

function* enableNewUserChannel(encodedEmail) {
  const chan = yield call(loginApi.getNewUserWithChannel, encodedEmail);
  try {
    while (true) {
      const newUser = yield take(chan);
      yield put({ type: actions.UPDATE_CHANNEL, payload: { key: "newUser", val: chan } });
      if (!newUser) {
        yield fork(showErrorMsg, "New user not found.");
        return;
      } else {
        if (newUser.webSignout) {
          // do forced signout
        } else {
          yield put({ type: actions.UPDATE_FIREBASE_OBJ, payload: { key: "newUser", val: newUser } });
        }
      }
    }
  } finally {
    console.log("end new user channel");
  }
}

function* enableSchoolConfigChannel(schoolName) {
  const chan = yield call(loginApi.fetchSchoolConfigWithChannel, schoolName);
  try {
    while (true) {
      const schoolObj = yield take(chan);
      yield put({ type: actions.UPDATE_CHANNEL, payload: { key: "schoolConfig", val: chan } });
      ;
      if (!schoolObj) {
        yield fork(showErrorMsg, "School configuration not found.");
        return;
      }
      if (schoolObj.deactivate) {
        yield fork(showErrorMsg, "School is deactivated.");
      } else {
        yield put({ type: actions.UPDATE_FIREBASE_OBJ, payload: { key: "schoolConfig", val: schoolObj } });
      }

    }
  } finally {
    console.log("end new user channel");
  }
}

export function* logout() {

  try {
    yield call(loginApi.initMasterConfig);
    yield call(loginApi.logout);
    yield call(loginApi.clearLocalStorage);
    yield put({ type: actions.CLOSE_CHANNELS })
    yield put({ type: actions.SIGN_OUT_SUCCESS, payload: null })
  } catch (err) {
    Helper.notifyBugsnag(err, true);
    console.log("Failed to logout", err);
  }

}

function* reinstateLogin(request) {
  let payload = request.payload;
  try {
    yield call(loginApi.initMasterConfig);

    yield call(loginApi.triggerAuthStateListener);

    let newUser = JSON.parse(getItem("user"));
    if (!newUser) {
      yield fork(showErrorMsg, "Failed to login. New user not found.");
      return;
    }
    //*****************************************

    let schoolConfig = JSON.parse(getItem("schoolConfig"));
    if (!schoolConfig) {
      yield fork(showErrorMsg, "Failed to login. School configuration not found.");
      return;
    }
    //*****************************************

    let encodedEmail = getItem("loggedInEncodedEmail");
    yield fork(continueFromSecondaryDb, schoolConfig, newUser, encodedEmail, payload?.noChannel, false);

  } catch (err) {
    Helper.notifyBugsnag(err, true);
    console.log("Failed to reinstate login", err);
    yield fork(logout);
  }
}

function* signUpAccount(request) {
  try {
    let payload = request.payload;
    // let response = yield call(callApi, firebase, "post", endpoint, reqObj);
    var response = yield call(
      loginApi.signupAccount,
      payload.email,
      payload.password,
      payload.countryCode,
      payload.phoneNumber,
      payload.name,
      payload.address,
      payload.schoolName,
      payload.firebase,
      payload.selectedGender,
      payload.timezone
    );

    if (response && response.status == 200) {
      notification("success", response.body.message);
      yield put({
        type: actions.SIGN_UP_SUCCESS,
        signupStatus: response,
      });
    } else {
      notification("error", response.body.message);
      yield put({
        type: actions.LOGIN_SAGA_FAILED,
      });
    }
  } catch (err) {
    Helper.notifyBugsnag(err, true);
    console.log("failed to sign up account", err);
    yield put({
      type: actions.LOGIN_SAGA_FAILED,
    });
  }
}
function* initLogin(request) {
  try {
    //
    console.log("it is working", request.payload.loginType)
    let auth0 = yield call(authHelper.getauth0Instance, request.payload.loginType)
  } catch (err) {
    console.log("failed to sign up account", err);
  }
}



function* continueWithNewUser(request) {
  const { newUser, schoolConfig } = request.payload;
  let newUserObj
  newUser.centerId = schoolConfig.centerId;
  if (newUser.userType === "PARENT") {
    newUser.schoolName = newUser.centerId;
  }
  let encodedEmail = Helper.getEncodedValue(newUser.signInProvider);
  setItem("user", newUser);
  setItem("schoolConfig", schoolConfig);
  if (!schoolConfig) {
    yield fork(showErrorMsg, "Failed to login. School configuration not found.");
    return;
  }
  yield fork(continueFromSecondaryDb, schoolConfig, newUser, encodedEmail, true, true);
}



export default function* rootSaga() {
  yield takeLatest(actions.SIGN_IN, signIn);
  yield takeLatest(actions.SIGN_OUT, logout);
  yield takeLatest(actions.VALIDATE_USER_LOGIN, reinstateLogin);
  yield takeLatest(actions.SIGN_UP_ACCOUNT, signUpAccount)
  yield takeLatest(actions.CONTINUE_WITH_NEW_USER, continueWithNewUser);
  yield takeLatest(actions.FETCH_NEW_USERS, fetchNewUsers);
  yield takeLatest(actions.INIT_LOGIN, initLogin)

}

