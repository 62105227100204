import { createSlice } from '@reduxjs/toolkit'
export const userSlice = createSlice({
    name: 'users',
    initialState: {
        isLoading: false,
        operationType: "",
        userList: [],
        userDetails: undefined,
        dateRangedUsersList: [],
        lastCreatedUser: undefined
    },
    reducers: {
        getAllUsers: (state, action) => {
            state.isLoading = true;
        },
        sagaFailed: (state, action) => {
            state.isLoading = false;
            state.operationType = ""
        },
        sagaSuccess: (state, action) => {
            if (action.payload) {
                Object.assign(state, action.payload);
            }
        },
        getUserDetails: (state, action) => {
            state.isLoading = true;
        },
        createUser: (state, action) => {
            state.isLoading = true;
        },
        resetOperationType: (state, action) => {
            state.operationType = ""
        },
        deleteUser: (state, action) => {
            state.isLoading = true;
        },
        getUsersListByDateRange: (state, action) => {
            state.isLoading = true; 
        }, 
        deleteAccountUserLink: (state, action) => {
            state.isLoading = true; 
        }, 
        delinkAccountFromUser: (state, action) => {
            state.isLoading = true; 
        }, 
        linkAccountToUser: (state, action) => {
            state.isLoading = true; 
        },
        linkNewUserToLinkedAccounts: (state, action) => {
            state.isLoading = true; 
        }
    }
})

export const {
    getAllUsers,
    getUsersListByDateRange,
    sagaFailed,
    sagaSuccess,
    getUserDetails,
    createUser,
    resetOperationType,
    deleteUser, 
    deleteAccountUserLink, 
    delinkAccountFromUser, 
    linkAccountToUser, 
    linkNewUserToLinkedAccounts  
} = userSlice.actions

export default userSlice.reducer