// sharedresources/src/index.js

import * as loginActions from './redux/login/reducer';
import * as bookingActivityActions from './redux/bookingActivity/reducer';
import * as dashboardActions from './redux/dashboard/reducer';
import * as calendarActions from './redux/calendar/reducer';
import * as invoiceActions from './redux/invoice/reducer';
import * as multicenterActions from './redux/multicenter/reducer';
import * as notificationActions from './redux/notification/reducer';
import * as packageActions from './redux/package/reducer';
import * as parentActions from './redux/parent/reducer';
import * as studentActions from "./redux/students/reducer";
import * as settingActions from "./redux/setting/reducer";
import * as staffActions from "./redux/staffs/reducer";
import * as reportActions from "./redux/reports/reducer"; 
import LayoutWrapper from "./components/layoutWrapper/page";
import * as wrapperComponents from "./components/wrapperComponents/page";
import PrefixIconButton from './components/buttons/prefixIconButton';
import colors from './libs/colorFactory';
import * as accountActions from "./redux/accounts/reducer";
import Helper from './util/helper';
import SpinWrapper from './components/SpinWrapper/page';
import LinkWrapper from './components/linkWrapper/page';
import AccountProfile from './components/accountProfile/page';
import AccountUsers from './components/accountUsers/page';
import * as userActions from "./redux/users/reducer";
import { notification } from './util/notification';
import FormWrapper from './components/formWrapper/page';
import Draggable from './components/dragNDropUtils/draggable';


export {
    loginActions,
    bookingActivityActions,
    dashboardActions,
    calendarActions,
    invoiceActions,
    multicenterActions,
    notificationActions,
    packageActions,
    parentActions,
    studentActions,
    settingActions,
    reportActions, 
    staffActions,
    LayoutWrapper,
    wrapperComponents,
    PrefixIconButton,
    colors,
    accountActions,
    Helper,
    SpinWrapper,
    LinkWrapper,
    AccountProfile,
    AccountUsers,
    userActions,
    notification,
    FormWrapper,
    Draggable 
};


export { default as Providers } from "./redux/providers"
export { default as store } from "./redux/store";
export { default as actions } from "./redux/actionType";
export { default as RootState } from "./redux/reducers";
export { default as formatMsg } from "./util/formatMsg"
